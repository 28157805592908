import { graphql } from 'gatsby';
import React from 'react';

import Header from '../components/layouts/header';
import Layout from '../components/layouts/main';
import SEO from '../components/layouts/seo';
import ProjectsList from "../components/projects-list";

const Projects = ({ data, location }) => {
  return (
    <Layout>
      <SEO title="Projects" location={location} />
      <Header metadata={data.site.siteMetadata} />
      <ProjectsList projects={data.site.siteMetadata.projects} />
    </Layout>
  );
};

export default Projects;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        name
        title
        description
        about
        twitter
        github
        linkedin
        devto
        stackoverflow
        leetcode
        discord
        email
        projects {
          name
          description
          link
          github
        }
      }
    }
  }
`;
